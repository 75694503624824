import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import {
  BodyTitle,
  Title,
  Button,
  Box,
  Divider,
  Link
} from '@firsttable/web-components/atoms';
import { awsImage } from '@firsttable/functions';
import { toArray } from 'react-emoji-render';

import NavLink from '../components/atoms/NavLink';

const parseEmojis = (value) => {
  if (!value) {
    return '';
  }
  const emojisArray = toArray(value);

  // toArray outputs React elements for emojis and strings for other
  return emojisArray.reduce((previous, current) => {
    if (typeof current === 'string') {
      return previous + current;
    }
    return previous + current.props.children;
  }, '');
};

const Iframe = ({ iframe }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: iframe || '' }} />
);

Iframe.propTypes = {
  iframe: PropTypes.any,
};

const ImageExtractor = ({ src, alt, width, height, classes }) => {
  if (src && src.search(/public/) !== -1) {
    // for public path images
    const key = src.match(new RegExp('/public/([^]+)'));
    const awsURI = `/public/${key[1]}`;
    return (
      <Box
        as="span"
        css={`
          display: block;
          width: 100%;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        `}
      >
        <img
          src={awsImage(awsURI, width, height)}
          alt={alt}
          className={`img-fluid ${classes}`}
        />
      </Box>
    );
  }
  return null;
};

ImageExtractor.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
};

export default (content) =>
  parse(parseEmojis(content), {
    replace(node) {
      const { children, name: tagName, prev, next } = node;
      switch (tagName) {
        case 'h1':
          return (
            <Title as={tagName}>
              {domToReact(children)}
            </Title>
          );
        case 'h2':
          return (
            <BodyTitle as={tagName} size="l">
              {domToReact(children)}
            </BodyTitle>
          );
        case 'h3':
          return (
            <BodyTitle as={tagName} size="m">
              {domToReact(children)}
            </BodyTitle>
          );
        case 'h4':
        case 'h5':
        case 'h6':
          return (
            <BodyTitle as={tagName} size="s">
              {domToReact(children)}
            </BodyTitle>
          );
        case 'a': {
          if (node.attribs.class && node.attribs.class.match('btn')) {
            return (
              <Button href={node.attribs.href} target="__blank" rel="noopener">
                {domToReact(children)}
              </Button>
            );
          }
          if (
            node.attribs.href &&
            (node.attribs.href.startsWith('http') ||
              node.attribs.href.startsWith('mailto'))
          ) {
            return (
              <Link
                href={node.attribs.href}
                color="brand.red"
                target="__blank"
                rel="noopener"
              >
                {domToReact(children)}
              </Link>
            );
          }
          return (
            <Link
              NavComponent={NavLink}
              to={node.attribs.href}
              color="brand.red"
            >
              {domToReact(children)}
            </Link>
          );
        }
        case 'p': {
          let imgChild = null;
          // return <Text mb={!next ? 0 : null}>{domToReact(children)}</Text>;
          children.forEach((child) => {
            if (child.name === 'img') {
              imgChild = {
                src: child.attribs.src,
                alt: child.attribs.alt,
                width: child.attribs.width,
                height: child.attribs.height,
                classes: child.attribs.class,
              };
            }
          });
          if (imgChild) {
            return <ImageExtractor {...imgChild} {...node.attribs} />;
          }
          return node;
        }
        case 'hr':
          return <Divider mt="20px" mb="20px" />;
        case 'img': {
          const { src, alt } = node.attribs;
          if (!src) {
            return null;
          }
          return <ImageExtractor src={src} alt={alt} />;
        }
        case 'div': {
          if (node.attribs['data-pd-webforms'] !== undefined) {
            const iframe = `<iframe src="${node.attribs['data-pd-webforms']}?embeded=1&amp;uuid=idngdw3i" scrolling="no" style="border: none; overflow: hidden; width: 100%; height: 1325px;"></iframe>`;
            return <Iframe iframe={iframe} />;
          }
          if (node.attribs['data-url'] !== undefined) {
            const iframe = `<iframe src="${node.attribs['data-url']}?embed_domain=www.firsttable.co.nz&amp;embed_type=Inline" frameborder="0" style="border: none; overflow: hidden; width: 100%; height: 1325px;"></iframe>`;
            return <Iframe iframe={iframe} />;
          }

          return node;
        }
        default: {
          // remove empty html tags
          if (
            node.type === 'text' &&
            node.data.length === 1 &&
            node.data !== '"' &&
            node.data !== '.'
          ) {
            return <></>;
          }
          // if (node.type === 'text') return <>{parseEmojis(node.data)}</>;
          return node;
        }
      }
    },
  });
