import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import {getUser, setUserEvent} from '@firsttable/functions';
import parseHtml from '../helpers/parseHtml';
import Logger from '../helpers/logger';


const logger = new Logger('SEO');

const SEO = ({
  description,
  lang,
  meta,
  title,
  ogImage,
  ogType,
  metaTags,
  slug,
  id,
}) => {
  const { site, siteConfig, giftVoucherPage } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            siteName
            fbAppId
            admins
            auFBPage
            nzFBPage
            experienceQTFBPage
            locale
            pageType
            siteUrl
          }
        }
        siteConfig {
          language
        }
        giftVoucherPage {
          ogImage
          foreignId
          metaTitleFormatted
          metaDescription
          metaTags
          content
        }
      }
    `,
  );

  function getMetaTagsFormatted(metaTagsUnformatted) {
    return typeof metaTagsUnformatted === 'string'
      ? metaTagsUnformatted.split('\n').join('')
      : null;
  }

  let pageId = id;
  let pageOGImage = ogImage;
  let metaDescription = description || site.siteMetadata.description;
  let metaTitle = title || site.siteMetadata.title;
  let metaTagsFormatted = getMetaTagsFormatted(metaTags);
  let siteUrl = `${site.siteMetadata.siteUrl}${slug}`;

  const siteLanguage = siteConfig.language || lang;
  const type = ogType || site.siteMetadata.pageType;
  const location = useLocation();
  const user = getUser();

  if (location.pathname === '/gift-vouchers/' && giftVoucherPage) {
    pageId = giftVoucherPage.foreignId;
    metaTitle = giftVoucherPage.metaTitleFormatted;
    metaDescription = giftVoucherPage.metaDescription;
    pageOGImage = giftVoucherPage.ogImage;
    metaTagsFormatted = getMetaTagsFormatted(giftVoucherPage.metaTags);
    siteUrl = `${site.siteMetadata.siteUrl}/gift-vouchers/`;
  }

  useEffect(() => {
    if (pageId) {
      setUserEvent(user);
    }
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang: siteLanguage,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: type,
        },
        { property: 'og:site_name', content: site.siteMetadata.siteName },
        { property: 'og:locale', content: site.siteMetadata.locale },
        { property: 'og:image', content: pageOGImage },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'fb:admins', content: site.siteMetadata.admins },
        { property: 'fb:app_id', content: site.siteMetadata.fbAppId },
        { property: 'fb:pages', content: site.siteMetadata.auFBPage },
        { property: 'fb:pages', content: site.siteMetadata.nzFBPage },
        { property: 'fb:pages', content: site.siteMetadata.experienceQTFBPage },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'rFK9MuCD7gJPsAM08I3iE1ch93LkgtxugxW5G1Wtih0',
        },
      ].concat(meta)}
    >
      {metaTagsFormatted && parseHtml(metaTagsFormatted)}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  ogImage: '',
  slug: '',
  id: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  ogImage: PropTypes.string,
  ogType: PropTypes.string,
  metaTags: PropTypes.string,
  slug: PropTypes.string,
  id: PropTypes.any,
};

export default SEO;
